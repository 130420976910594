import React, { useState } from "react";
import { useEffect } from "react";
import TableComponent from "../../components/DataTable";
import Select from "react-select";
import {
  getMembersData,
  getUserMembersData,
} from "../../services/auth-services";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { TiTick } from "react-icons/ti";
import { AiOutlineClear } from "react-icons/ai";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    width: '210mm',  // A4 width
    minHeight: '297mm', // A4 height
    // padding: '5mm', 
  },
});
const MembersData = () => {
  const [data, setData] = useState(null);
  console.log('data: ', data);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([
    { column: "", type: "icontains", query: "", start_date: "", end_date: "" },
  ]);
  const [columns, setColumns] = useState([
    { value: "last_name", label: "Last name" },
    { value: "address", label: "Address" },
  ]);
  const filterTypes = [
    { value: "startswith", label: "Starts with" },
    { value: "icontains", label: "Includes" },
  ];
  const [checkValue, setCheckBox] = useState({
    executive_board: 0,
    committee_member: 0,
    life_time: 0,
  })
  const handleFilterChange = (index, field, value, type) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    newFilters[index].filterType = type;
    setFilters(newFilters);
  };

  const addFilter = () => {
    setFilters([
      ...filters,
      {
        column: "",
        type: "icontains",
        query: "",
        start_date: "",
        end_date: "",
      },
    ]);
  };
  const removeFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const applyFilters = () => {
    fetchEmployeesWithFilters(filters);
  };
  const fetchEmployeesWithFilters = async (filters) => {
    setLoading(true)
    const hasEmptyValues = filters.some((filter) =>
      filter.column === "" || filter.column === "date_paid"
        ? filter.start_date === "" || filter.end_date === ""
        : filter.query === ""
    );
    if (hasEmptyValues) {
      toast.warn("Please fill all fields for filters.");
      setLoading(false)
    } else {
      const tokenData = JSON.parse(localStorage.getItem("token"));
      getMembersData(filters, tokenData, checkValue)
        .then((res) => {
          setData(res?.data?.results);

          setLoading(false);
          if (res.data.length > 0) {
            setColumns(Object.keys(res.data.results[0]));
          }
        })
        .catch((error) => {
          console.error("Error fetching filtered employees:", error);
          setLoading(false);
        });
    }
  };
  const handleClear = (index) => {
    setFilters((currentFilters) =>
      currentFilters.map((filter, filterIndex) =>
        filterIndex === index
          ? {
            column: "",
            type: "icontains",
            query: "",
            start_date: "",
            end_date: "",
          }
          : filter
      )
    );
  };
  const handleMembersData = () => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const tokenData = JSON.parse(localStorage.getItem("token"));
    getUserMembersData(tokenData)
      .then((res) => {
        setData(res?.data.results);
        setColumns(
          Object.keys(res?.data.results[0]).map((key) => ({
            value: key,
            label: key
              .replace(/_/g, " ")
              .replace(/^\w/, (c) => c.toUpperCase()),
            type: typeof res.data.results[0][key]
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleMembersData();
  }, []);

  const column = [
    {
      name: "Id",
      selector: (row) => row?.id,
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "50px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => (row?.email ? row?.email : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "250px",
    },
    {
      name: "DST",
      selector: (row) => (row?.dst ? row?.dst : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "60px",
    },
    {
      name: "Department",
      selector: (row) => (row?.department ? row?.department : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "200px",
    },
    {
      name: "Title",
      selector: (row) => (row?.title ? row?.title : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Mi",
      selector: (row) => (row?.mi ? row?.mi : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Address",
      selector: (row) => (row?.address ? row?.address : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "200px",
    },
    {
      name: "City",
      selector: (row) => (row?.city ? row?.city : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "120px",
    },
    {
      name: "st",
      selector: (row) => (row?.st ? row?.st : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "50px",
    },
    {
      name: "Zip Codes",
      selector: (row) => (row?.zip_code ? row?.zip_code : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
      width: "100px",
    },
    {
      name: "Date Paid",
      selector: (row) => new Date(row?.date_paid).toLocaleString(),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Paid Dues",
      selector: (row) => (row?.paid_dues ? "True" : "False"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Notes",
      selector: (row) => (row?.notes ? row?.notes : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Executive Board",
      selector: (row) => (row?.executive_board ? "True" : "False"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Committee Member",
      selector: (row) => (row?.committee_member ? "True" : "False"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Committee",
      selector: (row) => (row?.committee ? row?.committee : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "New Member",
      selector: (row) => (row?.new_member ? "True" : "False"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Paid Next Year",
      selector: (row) => (row?.paid_next_year ? "True" : "False"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Phone Number",
      selector: (row) => (row?.phone_number ? row?.phone_number : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Life Time",
      selector: (row) => (row?.lifetime ? "True" : "False"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
    {
      name: "Lapel Pin",
      selector: (row) => (row?.lapel_pin ? row?.lapel_pin : "Not Found"),
      style: {
        justifyContent: "left",
        borderLeft: "0.1px solid #d7d7d7",
        fontSize: "11px",
      },
    },
  ];
  const generateCSVData = () => {
    const csvData = data !== null && data?.map((item) => ({
      Id: item.id,
      Name: item.name,
      Email: item.email,
      Address: item.address,
      Zip_code: item.zip_code,
      Title: item.title,
      Department: item.department,
      Dst: item.dst,
      Mi: item.mi,
      City: item.city,
      St: item.st,
      Date_paid: item.date_paid,
      Paid_dues: item.paid_dues,
      Notes: item.notes,
      Executive_board: item.executive_board,
      Committee_member: item.committee_member,
      Committee: item.committee,
      Year_2007: item.year_2007,
      Year_2008: item.year_2008,
      Year_2009: item.year_2009,
      Year_2010: item.year_2010,
      New_member: item.new_member,
      Paid_next_year: item.paid_next_year,
      Phone_number: item.phone_number,
      lifetime: item.lifetime,
      Lapel_pin: item.lapel_pin,
    }));
    return csvData;
  }
  const MemberDropdown = [
    { value: "", label: "Select member class" },

    { value: "life_member", label: "Life Member" },
    { value: "honorary_membership", label: "Honorary Membership" },
    { value: "associate_member", label: "Associate Member" },
    { value: "none", label: "None" },
  ];
  const TableRow = ({ name, address, email, phone }) => (

    <View style={{ flexDirection: 'row', margin: 2, gap: 2 }}>
      <View style={{
        width: '33.3%',
        borderWidth: 0.5,
        borderRadius: 3,
        padding: 6,
        gap: 3
      }}>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{"Name : " + name}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{address ? "Address : " + address : "Not Found"}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{email ? "Email : " + email : "Not Found"}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{phone ? "Phone : " + phone : "Not Found"}</Text>
      </View>
      <View style={{
        width: '33.3%',
        borderWidth: 0.5,
        borderRadius: 3,
        padding: 6,
      }}>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{"Name : " + name}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{address ? "Address : " + address : "Not Found"}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{email ? "Email : " + email : "Not Found"}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{phone ? "Phone : " + phone : "Not Found"}</Text>
      </View>
      <View style={{
        width: '33.3%',
        borderWidth: 0.5,
        borderRadius: 3,
        padding: 6,
      }}>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{"Name : " + name}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{address ? "Address : " + address : "Not Found"}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{email ? "Email : " + email : "Not Found"}</Text>
        <Text style={{ textAlign: "left", fontSize: 10 }}>{phone ? "Phone : " + phone : "Not Found"}</Text>
      </View>
    </View>
  );
  const MyDocument = ({ data }) => {
    // Function to chunk data into groups of 12
    const chunkData = (data, size) => {
      let result = [];
      for (let i = 0; i < data.length; i += size) {
        result.push(data.slice(i, i + size));
      }
      return result;
    };

    const pagesData = chunkData(data, 11); // Create pages with 12 rows each

    return (
      <Document>
        {pagesData.map((pageData, index) => (
          <Page key={index} size="A4" style={styles.page}>
            {pageData.map((item, idx) => (
              <TableRow key={idx} name={item.name} address={item.address} email={item.email} phone={item.phone} />
            ))}
          </Page>
        ))}
      </Document>
    );
  };

  return (
    <div className="mt-5">
      <div className="text-3xl my-6 md:my-6 text-center mx-auto w-fit text-primary font-medium">
        Members Data
      </div>
      <div className="md:w-1/2 mb-5 flex flex-col gap-5 mx-auto border p-5 rounded-lg shadow-xl">
        {filters.map((filter, index) => (
          <div key={index} className="flex flex-col gap-5 border px-5 py-3 rounded-lg">
            <div key={index} className="flex gap-5 w-full">
              <div className="w-full">
                <Select
                  options={columns}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      fontSize: "12px",
                      ":focus": {
                        borderColor: "black",
                      },
                      backgroundColor: state.isFocused
                        ? "transparent"
                        : "transparent",
                      indicatorsContainer: (prevStyle, state) =>
                        state.isMulti
                          ? {
                            ...prevStyle,
                          }
                          : null,
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#000000",
                      fontSize: "12px",
                    }),
                  }}
                  value={
                    columns.find(
                      (option) => option.value === filters[index].column
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleFilterChange(index, "column", selectedOption.value, selectedOption.type)
                  }
                  placeholder="Select Column"
                />
              </div>
              {filter.column !== "date_paid" && filter.filterType !== "boolean" ? (
                <div className="w-full">
                  <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        fontSize: "12px",
                        ":focus": {
                          borderColor: "black",
                        },

                        backgroundColor: state.isFocused
                          ? "transparent"
                          : "transparent",
                        indicatorsContainer: (prevStyle, state) =>
                          state.isMulti
                            ? {
                              ...prevStyle,
                            }
                            : null,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#000000",
                        fontSize: "12px",
                      }),
                    }}
                    options={filterTypes}
                    value={filterTypes.find(
                      (option) => option.value === filters[index].type
                    )}
                    onChange={(selectedOption) =>
                      handleFilterChange(index, "type", selectedOption.value)
                    }
                    placeholder="Filter Type"
                  />
                </div>
              ) : null}
            </div>
            <div className="flex gap-5 items-center ">
              {filter?.column === "date_paid" ? (
                <div className="flex gap-5 w-full">
                  <TextInput
                    label={"Start date"}
                    type={"date"}
                    onChange={(e) =>
                      handleFilterChange(index, "start_date", e.target.value)
                    }
                  />
                  <TextInput
                    label={"End date"}
                    type={"date"}
                    onChange={(e) =>
                      handleFilterChange(index, "end_date", e.target.value)
                    }
                  />
                </div>
              ) : filter.filterType === "boolean" ?
                <select className="border border-gray-300 rounded-md text-xs px-2 h-[38px] outline-blue-400 w-full" id="cars" onChange={(e) => handleFilterChange(index, "query", e.target.value, 'boolean')} >
                  <option value="">select true or false</option>
                  <option value="1">True</option>
                  <option value="0">False</option>
                </select>

                : filter.column === "membership_class" ?
                  <select className="border border-gray-300 rounded-md text-xs px-2 h-[38px] outline-blue-400 w-full" id="cars" onChange={(e) => handleFilterChange(index, "query", e.target.value, 'string')} >
                    {MemberDropdown.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>{item.label}</option>
                      )
                    })}
                  </select>

                  : (
                    <TextInput
                      type="text"
                      placeholder={`Enter your query..`}
                      value={filter.query}
                      onChange={(e) =>
                        handleFilterChange(index, "query", e.target.value)
                      }
                    />
                  )}
              <div className="flex items-center">
                <div
                  onClick={() => handleClear(index)}
                  className="rounded-full cursor-pointer h-[35px] border shadow-sm w-[35px] p-1 flex flex-col items-center justify-center"
                >
                  <AiOutlineClear className=" h-4 w-4 " />
                </div>
                {filters.length > 1 && (
                  <svg
                    onClick={() => removeFilter(index)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_44_1073)">
                      <rect
                        x="6"
                        y="6"
                        width="28"
                        height="28"
                        rx="14"
                        fill="white"
                      />
                      <path
                        d="M15.0992 25.6C15.0992 25.9713 15.2467 26.3274 15.5093 26.5899C15.7718 26.8525 16.1279 27 16.4992 27H23.4992C23.8705 27 24.2266 26.8525 24.4892 26.5899C24.7517 26.3274 24.8992 25.9713 24.8992 25.6V17.2H26.2992V15.8H23.4992V14.4C23.4992 14.0287 23.3517 13.6726 23.0892 13.4101C22.8266 13.1475 22.4705 13 22.0992 13H17.8992C17.5279 13 17.1718 13.1475 16.9093 13.4101C16.6467 13.6726 16.4992 14.0287 16.4992 14.4V15.8H13.6992V17.2H15.0992V25.6ZM17.8992 14.4H22.0992V15.8H17.8992V14.4ZM17.1992 17.2H23.4992V25.6H16.4992V17.2H17.1992Z"
                        fill="#CF5D5E"
                      />
                      <path
                        d="M17.9004 18.6H19.3004V24.2H17.9004V18.6ZM20.7004 18.6H22.1004V24.2H20.7004V18.6Z"
                        fill="#CF5D5E"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_44_1073"
                        x="0"
                        y="0"
                        width="40"
                        height="40"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_44_1073"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_44_1073"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="flex  items-center gap-5 flex-wrap justify-center">
          <label className="text-xs select-none flex items-center gap-2 font-semibold">
            <input checked={checkValue.executive_board === 1} onChange={(e) => setCheckBox({ ...checkValue, executive_board: e.target.checked ? 1 : 0 })} type="checkbox" className="accent-yellow-200" /> Executive board
          </label>
          <label className="text-xs select-none flex items-center gap-2 font-semibold">
            <input checked={checkValue.committee_member === 1} onChange={(e) => setCheckBox({ ...checkValue, committee_member: e.target.checked ? 1 : 0 })} type="checkbox" class="accent-yellow-200" /> Committee Member
          </label>
          <label className="text-xs select-none flex items-center gap-2 font-semibold">
            <input checked={checkValue.life_time === 1} onChange={(e) => setCheckBox({ ...checkValue, life_time: e.target.checked ? 1 : 0 })} type="checkbox" class="accent-yellow-200" /> Life Time
          </label>
        </div>
        <div className="flex gap-5 justify-end">
          <Button
            customClass={"!text-xs "}
            onClick={addFilter}
            text={"+ Add Filter"}
          />
          <Button customClass={"!text-xs !gap-0"} onClick={applyFilters}>
            <TiTick /> Apply Filter
          </Button>
        </div>
      </div>
      <div className="">
        {data !== null &&
          <div className="mb-3 w-fit ml-auto">
            <PDFDownloadLink document={<MyDocument data={data} />} className='text-xs p-2 mb-3 px-4 rounded-md flex w-fit justify-end bg-primary ml-auto text-white' fileName="Mailing_lebels.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Export PDF'
              }
            </PDFDownloadLink>
            <CSVLink className=" border  border-primary gap-3 text-white  text-sm bg-primary font-medium leading-[24px] !w-fit px-6 py-2 rounded-xl" data={generateCSVData()} filename="cancelled-orders(data).csv">
              Export to excel
            </CSVLink>
          </div>}
        <div className="w-full pb-10">
          {
            data && <TableComponent progressPending={loading} {...{ data, column }} />
            //
          }
        </div>
      </div>
    </div>
  );
};

export default MembersData;
