import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput";
import { getEventInfo, postEventInfo } from "../../services/auth-services";
import Loading from '../../components/Loading';

const MidWinterForm = () => {
    const [loading, setLoading] = useState(false)
    const [eventDetails, setEventDetails] = useState();
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        title: "",
        fire_department: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        phone: "",
        email: "",
        number_of_participants: "0",
        number_of_guests: "0",
    })
    console.log(formData, "formData")
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }
    const getEventDetails = async () => {
        try {
            const res = await getEventInfo();
            setEventDetails(res.data);

        } catch (error) {
            console.log(error);
        }
    };
    const postEvent = async () => {
        setLoading(true)
        try {
            const res = await postEventInfo(formData)
            if (res.data.approval_url) {
                window.open(res.data.approval_url)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }

    }
    useEffect(() => {
        getEventDetails();
    }, []);
    return (
        <>
            {loading && <Loading />}
            <div className="p-5 md:p-20">
                <div className="flex justify-around flex-wrap md:flex-nowrap items-center gap-16 md:gap-5">
                    <img
                        src="/1st_logo.jpg"
                        width={185}
                        height={185}
                        className="object-fit  hidden md:block"
                    />
                    <img src="/Logo.png" className="h-56 object-fit" />
                    <img
                        src="/3rd_logo.jpg"
                        width={185}
                        height={185}
                        className="object-fit hidden md:block"
                    />
                </div>
                <div className="AppText mt-20">
                    <h1 className="h1">2025 MID-WINTER FIRE CHIEF’S CONFERENCE</h1>
                    <h2 className="h2">“Facing the Future Together”</h2>
                    <h2 className="h2">January 8-9, 2025</h2>
                    <h1 className="h1">MSU Riley Center</h1>
                    <h2 className="h2">2200 5th St Meridian, MS 39301</h2>

                    <div className="md:w-2/3 mx-auto flex flex-col gap-5">
                        <div className="flex items-center gap-5 flex-wrap md:flex-nowrap">
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"first_name"}
                                value={formData?.first_name}
                                onChange={(e) => handleChange(e)}
                                label={"First Name: "}
                            />
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"last_name"}
                                value={formData?.last_name}
                                onChange={(e) => handleChange(e)}
                                label={"Last Name: "}
                            />
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"title"}
                                label={"Title: "}
                                value={formData?.title}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="flex items-center gap-5 flex-wrap md:flex-nowrap">
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"fire_department"}
                                label={"Fire Department: "}
                                value={formData?.fire_department}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"address"}
                                label={"Address: "}
                                value={formData?.address}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="flex items-center gap-5 flex-wrap md:flex-nowrap">
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"city"}
                                label={"City: "}

                                value={formData?.city}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"state"}
                                label={"State: "}
                                value={formData?.state}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"zipcode"}
                                label={"ZipCode: "}
                                value={formData?.zipcode}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="flex items-center gap-5 flex-wrap md:flex-nowrap">
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"phone"}
                                label={"Phone: "}
                                value={formData?.phone}
                                onChange={(e) => handleChange(e)}
                            />
                            <TextInput
                                inputStyle={"!h-11"}
                                bigLabel={"!mb-0.5 !text-[18px]"}
                                name={"email"}
                                label={"E-Mail: "}
                                value={formData?.email}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className="my-14">
                        <h2 className="h2 ">
                            {" "}
                            Registration Fee - $100.00 (Fee will increase to $115.00 after
                            {" " +
                                new Date("2024-12-16").toLocaleDateString("en-US", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })}
                            )
                        </h2>
                    </div>
                    <div className="container">
                        <div className="flex  gap-5  flex-col xs:flex-row">
                            {!eventDetails?.is_late_registration ? (
                                <div className="flex items-center gap-2 flex-wrap md:flex-nowrap border p-5 rounded-md">
                                    <TextInput
                                        inputStyle={"  !h-11 !text-center"}
                                        bigLabel={"!mb-1 !text-center !text-[18px]"}
                                        name={"number_of_participants"}
                                        placeholder={"Number of Registrants"}
                                        label={"Early Registration"}
                                        type={"number"}
                                        min={0}
                                        value={formData?.number_of_participants}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <TextInput
                                        inputStyle={"  !h-11 !text-center"}
                                        bigLabel={"!mb-1 !text-center !text-[18px]"}
                                        placeholder={"Please Enter Amount"}
                                        disabled
                                        label={`@ $${eventDetails?.registration_fee?.normal?.toFixed(
                                            2
                                        )} `}
                                        value={(eventDetails?.registration_fee?.normal * formData?.number_of_participants)?.toFixed(
                                            2
                                        )}
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center gap-2 flex-wrap md:flex-nowrap border p-5 rounded-md">
                                    <TextInput
                                        inputStyle={"  !h-11 !text-center"}
                                        bigLabel={"!mb-1 !text-center !text-[18px]"}
                                        name={"number_of_participants"}
                                        placeholder={"Number of Registrants"}
                                        label={"Late Registration "}
                                        min={0}
                                        type={"number"}
                                        value={formData?.number_of_participants}
                                        onChange={(e) => handleChange(e)}

                                    />
                                    <TextInput
                                        inputStyle={"  !h-11 !text-center"}
                                        bigLabel={"!mb-1 !text-center !text-[18px]"}
                                        placeholder={"Please Enter Amount"}
                                        disabled
                                        label={`@ $${eventDetails?.registration_fee?.late?.toFixed(
                                            2
                                        )} `}
                                        value={(eventDetails?.registration_fee?.late * formData?.number_of_participants)?.toFixed(2)}
                                    />
                                </div>
                            )}
                            <div className="flex items-center gap-2 flex-wrap md:flex-nowrap border p-5 rounded-md">
                                <TextInput
                                    inputStyle={"  !h-11 !text-center"}
                                    bigLabel={"!mb-1 !text-center !text-[18px]"}
                                    name={"number_of_guests"}
                                    type={"number"}
                                    min={0}
                                    value={formData?.number_of_guests}
                                    placeholder={"Number of Guest"}
                                    label={"Guest Registration "}
                                    onChange={(e) => handleChange(e)}
                                />
                                <TextInput
                                    inputStyle={"!h-11 !text-center"}
                                    bigLabel={"!mb-1 !text-center !text-[18px]"}
                                    placeholder={"Please Enter Amount"}
                                    disabled
                                    label={`@ $${eventDetails?.guest_fee?.toFixed(2)} `}
                                    value={(eventDetails?.guest_fee * formData?.number_of_guests)?.toFixed(2)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="left-column" style={{ fontSize: "22px" }}>
                            {/* Content for the left column */}
                            <h1>Please make all checks payable to:</h1>
                            {/* Content for the right column */}
                            <p className="p" style={{ color: "red" }}>
                                <h2>
                                    MS Fire Chief’s Association<br></br>
                                    P.O.Box 5231<br></br>
                                    Jackson, MS 39296
                                </h2>
                            </p>

                            <h2>Or </h2>

                            <p className="p" style={{ color: "blue" }}>
                                <h2>
                                    <strong onClick={() => postEvent()} className="cursor-pointer">
                                        <u>Click here to pay online</u>
                                    </strong>
                                </h2>
                            </p>
                        </div>
                    </div>

                    <div className=" flex !flex-wrap justify-around !md:flex-nowrap mx-auto">
                        <div className="middle-colum" style={{ fontSize: "22px" }}>
                            {/* Content for the left column */}
                            <h2 className="h2">Host Hotel: </h2>
                            <p className="p">
                                <strong>The Threefoot Hotel</strong>
                                <br></br>
                                601 22nd Avenue<br></br>
                                Meridian MS 39301<br></br>
                                Room Rate: $144-$149 per night<br></br>
                                Start Date Tuesday, January 7, 2025<br></br>
                                End Date Thursday, January 9, 2025<br></br>
                                Last Day to Book: Friday, December 6, 2024<br></br>
                                Phone: <strong>601-207-8700</strong>
                            </p>
                        </div>

                        <div className="middle-colmn" style={{ fontSize: "22px" }}>
                            {/* Content for the left column */}
                            <h2>
                                {" "}
                                <br></br>{" "}
                            </h2>
                            <p className="p">
                                <br></br>
                                <strong>Fairfield Inn & Suites</strong>
                                <br></br>
                                148 Highway 11 & 80<br></br>
                                Meridian MS 39301<br></br>
                                Room Rate: $114-$124 per night<br></br>
                                Start Date Tuesday, January 7, 2025<br></br>
                                End Date Thursday, January 9, 2025<br></br>
                                Last Day to Book: Friday, December 6, 2024<br></br>
                                Phone: <strong>601-453-3851</strong>
                            </p>
                        </div>
                    </div>

                    <p className="p !pt-5" style={{ fontSize: "18px" }}>
                        <strong>Group Name: MS Fire Chiefs Association</strong>
                    </p>
                </div>
            </div>
        </>

    );
};

export default MidWinterForm;
