import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainLayout from "./layout/mainLayout";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MainLayout>
				<ToastContainer
					transition={Slide}
					autoClose={1500}
					hideProgressBar
					position="top-center"
					draggable
				/>
				<App />
			</MainLayout>
		</BrowserRouter>
	</React.StrictMode>
);
