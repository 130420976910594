import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


const PasswordInput = ({
    placeholder,
    name,
    onChange,
    id,
    value,
    topSet,
    onKeyDown,
    onKeyUp,
    label
}) => {
    const [showPass, setShowPass] = useState(false);
    return (
        <div className="relative flex-col flex">
            <label
                htmlFor=""
                className={`text-xs font-semibold text-left`}
            >
                {label}
            </label>
            <input
                id={id}
                name={name}
                value={value}
                onKeyUp={onKeyUp}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                type={showPass ? "text" : "password"}
                className="border border-gray-200 rounded-lg px-2 h-10 outline-none"
            />
            {showPass ? (
                <AiOutlineEye
                    onClick={() => setShowPass(false)}
                    className={`absolute right-2 top-0 bottom-0 m-auto cursor-pointer text-primary ${topSet}`}
                />
            ) : (
                <AiOutlineEyeInvisible
                    onClick={() => setShowPass(true)}
                    className={`absolute right-2 top-0 bottom-0 m-auto cursor-pointer text-primary ${topSet}`}
                />
            )}
            {/* <FieldError text={error} /> */}
        </div>
    );
};

export default PasswordInput;
