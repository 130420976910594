import React, {useEffect, useState} from "react";
import MemberShipForm from "../components/forms/MemberShipForm";
import {
    CreateInvoice,
    CreateManager,
    checkValidateInvoice,
    editSingleMemberDepartment,
    getDepartments,
    getMemberData,
    getPayments,
    getPaymentsStatus,
    getPublicMembersData,
    getSingleDepartment,
    updateMemberData,
} from "../services/auth-services";
import {toast} from "react-toastify";
import Loading from "../components/Loading";

const default_fields = {
    name: "",
    address: "",
    city: "",
    state: "MS",
    country: "United States",
    zip_code: "",
    email: "",
    home_phone: "",
    department: 0,
    business_phone: "",
    dept_time: "",
    title: "",
    date_paid: "",
};
const MemberDropdown = [

    {value: "life_member", label: "Life Member"},
    {value: "honorary_membership", label: "Honorary Membership"},
    {value: "associate_member", label: "Associate Member"},
    {value: "none", label: "None"},
];
const MembershipDashboard = () => {
    const totalSteps = 3;
    const [isProgress, setIsProgress] = useState(1);
    console.log('isProgress: ', isProgress);
    const [formData, setFormData] = useState(default_fields);
    const [departmentList, setDepartmentList] = useState([]);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [selectedRows, setSelectedRows] = useState({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
    });
    const [department, setDepartment] = useState(null)
    const [isGroup, setIsGroup] = useState(false);
    const [userData, setUserData] = useState(
        JSON.parse(localStorage.getItem("user"))
    );

    useEffect(() => {
        setFormData({...formData, email: userData.email})
        // eslint-disable-next-line
    }, [userData])
    const [filters, setFilters] = useState([
        {column: "", type: "icontains", query: "", start_date: "", end_date: ""},
    ]);
    const [columns, setColumns] = useState([
        {
            value: "id",
            label: "Id",
            type: "number"
        },
        {
            value: "name",
            label: "Name",
            type: "string"
        },
        {
            value: "city",
            label: "City",
            type: "string"
        },
        {
            value: "department",
            label: "Department",
            type: "string"
        }
    ]);
    const filterTypes = [
        {value: "startswith", label: "Starts with"},
        {value: "icontains", label: "Includes"},
    ];
    const handleFilterChange = (index, field, value, type) => {
        const newFilters = [...filters];
        newFilters[index][field] = value;
        newFilters[index].filterType = type;
        setFilters(newFilters);
    };

    const addFilter = () => {
        setFilters([
            ...filters,
            {
                column: "",
                type: "icontains",
                query: "",
                start_date: "",
                end_date: "",
            },
        ]);
    };
    const removeFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const applyFilters = () => {
        fetchEmployeesWithFilters(filters);
    };
    const handleClear = (index) => {
        setFilters((currentFilters) =>
            currentFilters.map((filter, filterIndex) =>
                filterIndex === index
                    ? {
                        column: "",
                        type: "startswith",
                        query: "",
                        start_date: "",
                        end_date: "",
                    }
                    : filter
            )
        );
    };
    const fetchEmployeesWithFilters = async (filters) => {
        setLoading(true);
        const hasEmptyValues = filters.some((filter) =>
            filter.column === "" || filter.column === "date_paid"
                ? filter.start_date === "" || filter.end_date === ""
                : filter.query === ""
        );
        if (hasEmptyValues) {
            toast.warn("Please fill all fields for filters.");
            setLoading(false);
        } else {
            const tokenData = JSON.parse(localStorage.getItem("token"));
            getPublicMembersData(filters, tokenData)
                .then((res) => {
                    setData(res?.data?.results);
                    setLoading(false);
                    if (res.data.length > 0) {
                        setColumns(Object.keys(res.data.results[0]));
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching filtered employees:", error);
                    setLoading(false);
                });
        }
    };
    const handleSelectRow = (e) => {
        setSelectedRows(e);
        if (!paymentStatus) {
            addRow(formData);
        }
    };
    const summaryCol = [
        {
            name: "Id",
            selector: (row) => {
                return <div>{row.id}</div>;
            },
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "60px",
        },
        {
            name: "Name",
            selector: (row) => row?.name,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "200px",
        },

        {
            name: "Department",
            selector: (row) => {
                return row.department;
            },
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "300px",
        },

        {
            name: "City",
            selector: (row) => (row?.city ? row?.city : "Not Found"),
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
        },
        {
            name: "Amount",
            selector: (row) => '$' + row.amount,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
        },
    ];
    const column = [
        {
            name: "Id",
            selector: (row) => row.id,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "60px",
        },
        {
            name: "Name",
            selector: (row) => row?.name,
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "200px",
        },

        {
            name: "Department",
            selector: (row) => (row?.department ? row?.department : "Not Found"),
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "250px",
        },

        {
            name: "City",
            selector: (row) => (row?.city ? row?.city : "Not Found"),
            style: {
                justifyContent: "left",
                borderLeft: "0.1px solid #d7d7d7",
                fontSize: "11px",
            },
            width: "250px",
        },
    ];
    const handlePayment = () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("token"));
        const body = {invoice_number: selectedRows?.invoice_number};
        getPayments(body, token)
            .then((res) => {
                setLoading(false)
                window.location.href = res.data.approval_url;
            })
            .catch((error) => {
                setLoading(false)
                console.log("error: ", error);
            });
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };
    const getMembers = () => {
        const tokenData = JSON.parse(localStorage.getItem("token"));
        getDepartments(tokenData)
            .then((res) => {
                setDepartmentList(res.data);

            })
            .catch((error) => {
                console.log("error: ", error);
            });
    };
    useEffect(() => {
        getMembers();

        // eslint-disable-next-line
    }, []);
    const handleBack = () => {
        if (isProgress > 1) {
            if (isProgress === 3) {
                if (!isGroup) {
                    setIsProgress(isProgress - 2);
                } else {
                    setIsProgress(isProgress - 1);
                }
            } else {
                setIsProgress(isProgress - 1);
            }
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("token"));
        if (user) {
            getMemberData(JSON.parse(localStorage.getItem("token")))
                .then((response) => {
                    if (response.data) {
                        setFormData(response.data);

                        getSingleDepartment(tokenData, response.data.department)
                            .then((res) => {
                                setDepartment(res.data);
                            })
                            .catch((error) => {
                                console.log("error: ", error);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const tokenData = JSON.parse(localStorage.getItem("token"));
    const handleNext = () => {
        if (isProgress < 3) {
            if (isProgress === 2) {
                if (selectedRows.selectedCount < 1) {
                    toast.warn("Please select members.");
                    return;
                }
                setIsProgress(isProgress + 1);
                const token = JSON.parse(localStorage.getItem("token"));
                const body = {selectedMembers: selectedRows?.selectedRows};
                CreateInvoice(body, token)
                    .then((res) => {
                        setSelectedRows(res.data)
                    })
                    .catch((error) => {
                        console.log("error: ", error);
                    });
            } else if (isProgress === 1) {
                if (formData.id) {
                    setLoading(true);


                    const {membership_class, ...cleanedFormData} = formData;

                    console.log("formData:", cleanedFormData);
                    updateMemberData(cleanedFormData, tokenData)
                        .then((response) => {


                            setFormData(formData);
                            if (isGroup) {
                                setLoading(false)
                                setIsProgress(isProgress + 1);
                            } else {
                                setLoading(false)
                            }
                            if (!isGroup && !paymentStatus) {
                                const token = JSON.parse(localStorage.getItem("token"));
                                const body = {selectedMembers: [response.data]};
                                CreateInvoice(body, token)
                                    .then((res) => {
                                        setLoading(false)

                                        setSelectedRows(res.data)
                                        setIsProgress(isProgress + 2);
                                    })
                                    .catch((error) => {
                                        setLoading(false)
                                        console.log("error: ", error);
                                    });
                            }
                        })
                        .catch((err) => {
                            setLoading(false)
                            console.log(err);
                        });
                } else {
                    CreateManager(formData, tokenData)
                        .then((res) => {
                            setUserData((prevUser) => ({
                                ...prevUser,
                                is_member: true,
                            }));
                            const data = JSON.stringify(userData);
                            localStorage.setItem("user", data);
                            if (res.data) {
                                getMemberData(tokenData)
                                    .then((response) => {
                                        setFormData(res.data)
                                        if (response.data) {
                                            if (isGroup) {
                                                setIsProgress(isProgress + 1);
                                            }
                                            if (!isGroup) {
                                                setSelectedRows((prevSelectedRows) => ({
                                                    ...prevSelectedRows,
                                                    selectedCount: 1,
                                                    selectedRows: [res.data],
                                                }));
                                                const token = JSON.parse(localStorage.getItem("token"));
                                                const body = {selectedMembers: [res.data]};
                                                CreateInvoice(body, token)
                                                    .then((res) => {
                                                        setLoading(false)
                                                        setIsProgress(isProgress + 2);
                                                        setSelectedRows(res.data)
                                                    })
                                                    .catch((error) => {
                                                        console.log("error: ", error);
                                                    });
                                            }
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log("error: ", error);
                        });
                }
            }
        }
    };
    const addRow = (newRow) => {
        setSelectedRows((prevSelectedRows) => ({
            ...prevSelectedRows,
            // selectedCount:  prevSelectedRows.selectedCount + 1,
            selectedRows: [...prevSelectedRows.selectedRows, newRow],
        }));
    };

    useEffect(() => {
        getPaymentsStatus(tokenData)
            .then((res) => {
                setPaymentStatus(res.data.payment_status);
                setIsGroup(res.data.payment_status);
            })
            .catch((error) => {
                console.log("error: ", error);
            });
        // eslint-disable-next-line
    }, []);
    const handleSubmit = () => {
        handlePayment();
    };
    const validateChecker = () => {
        const tokenData = JSON.parse(localStorage.getItem("token"));
        editSingleMemberDepartment(tokenData, department.id, department).then((res) => {
            // toast.success('Deprtment edited successfully!')
            const body = {invoice_number: selectedRows?.invoice_number, billing_address: department.billing_address};
            checkValidateInvoice(tokenData, body).then((res) => {
            }).catch((error) => {
                console.log('error: ', error);
            })
        }).catch((error) => {
            console.log('error: ', error);

        })
    }
    return (
        <div className=" items-center justify-center">
            {loading && <Loading/>}
            <MemberShipForm
                {...{
                    formData,
                    setFormData,
                    handleChange,
                    departmentList,
                    MemberDropdown,
                    isProgress,
                    setIsProgress,
                    totalSteps,
                    handleNext,
                    handleBack,
                    handleSubmit,
                    data,
                    loading,
                    selectedRows,
                    setSelectedRows,
                    filters,
                    setFilters,
                    columns,
                    setColumns,
                    filterTypes,
                    handleFilterChange,
                    addFilter,
                    removeFilter,
                    applyFilters,
                    handleClear,
                    handleSelectRow,
                    column,
                    handlePayment,
                    agreeTerms,
                    setAgreeTerms,
                    summaryCol,
                    userData,
                    isGroup,
                    setIsGroup,
                    paymentStatus,
                    validateChecker,
                    department,
                    setDepartment
                }}
            />
        </div>
    );
};
export default MembershipDashboard;
